export function Insha() {
  return (
    <div className="flex-1 flex flex-col justify-center h-full w-full  gap-8  ">
      <div className="flex flex-col justify-center items-center  text-black cursor-pointer mt:10">
        <text className="text-8xl font-bold ">Hi,</text>
        <text className="text-5xl font-thin ">I am Salman</text>
      </div>

      <div className="flex w-full justify-center items-center flex-col gap-2">
        <p className="text-lg">Human += Engineer</p>
        <p className="text-xs text-gray-600">
          7+ Years of Experience as Software Engineer
        </p>
        <p className="text-xs text-gray-400">🇩🇪 Berlin, Germany </p>
      </div>
    </div>
  );
}
