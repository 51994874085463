import Salman from "../assets/salman2.jpg";

export const Slider = () => {
  return (
    <img
      src={Salman}
      alt="Salman"
      className="flex   w-atuo object-cover h-1/2 md:h-full  w-full md:w-1/2"
    />
  );
};
