import { Footer } from "./Footer";
import { Insha } from "./Insha";
import { InshaFeatures } from "./InstaFeatures";

export function Home() {
  return (
    <main className="flex flex-row flex-1  lg:flex-col  h-screen w-screen  bg-white overflow-hidden">
      <div className="flex  flex-col flex-1 md:flex-row w-full h-full">
        <Insha />

        <InshaFeatures />
      </div>
      <div className=" lg:flex-col w-full h-11 p-3 px-8 fixed bottom-0 ">
        <Footer />
      </div>
    </main>
  );
}
